import { NextPage } from 'next'
import Image from 'next/image'

import { useAuth } from '~/components/Auth/AuthProvider'
import { BusinessAction } from '~/components/Business/BusinessAction'
import { BusinessCard } from '~/components/Business/BusinessCard'
import { Spacer } from '~/components/Common/Spacer'
import { Tooltip } from '~/components/Common/Tooltip'
import Icon from '~/components/Icon'
import { HeaderTitleBackButtonWrapper } from '~/components/Wrapper/HeaderTitleBackButtonWrapper'
import { HeaderWrapper } from '~/components/Wrapper/HeaderWrapper'
import locale from '~/locale'

const BusinessPage: NextPage = () => {
    const { businesses } = useAuth()

    return (
        <>
            <div className="flex justify-center items-center w-full h-full">
                <div className="w-full h-full flex flex-col">
                    <HeaderWrapper>
                        <div className="flex max-w-3xl w-full mx-auto">
                            <HeaderTitleBackButtonWrapper
                                title={locale.id.business.title}
                                showBackButton={false}></HeaderTitleBackButtonWrapper>
                            <Spacer></Spacer>
                            {/* <Logout></Logout> */}
                        </div>
                    </HeaderWrapper>
                    <div className="w-full flex-grow flex flex-col items-center overflow-auto">
                        <div className="p-6 w-full bg-neutrals-light flex-grow flex flex-col">
                            <div className="flex-grow mb-6 overflow-auto border-b border-neutrals-200">
                                {businesses.length === 0 ? (
                                    <div className="mx-auto max-w-3xl justify-center items-center w-full h-full flex flex-col text-lg text-neturals-900">
                                        <Image
                                            src="/images/business/no-business.svg"
                                            width={200}
                                            height={200}
                                            unoptimized
                                            alt={locale.id.business.no_business}></Image>
                                        <p className="mt-5 capitalize ">{locale.id.business.no_business}</p>
                                        <p className="">{locale.id.business.create_or_join}.</p>
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5 pb-12 mx-auto max-w-3xl">
                                        {businesses.map((item, key) => (
                                            <BusinessCard business={item} key={key} type="listing"></BusinessCard>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <BusinessAction></BusinessAction>
                        </div>
                    </div>
                </div>
            </div>

            <a href="https://go.kelola.co/wa" target="_blank" rel="noreferrer">
                <div
                    className="fixed right-6 bottom-6 rounded-full bg-green-400 hover:bg-green-500 transition-colors text-neutrals-light flex items-center justify-center p-3"
                    role="button">
                    <Tooltip content={'Chat on WhatsApp'} variant="information">
                        <div className="flex items-center justify-center">
                            <Icon width={32} name="chat-outline"></Icon>
                        </div>
                    </Tooltip>
                </div>
            </a>
        </>
    )
}

export default BusinessPage
